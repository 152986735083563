import { memo, useCallback, useContext } from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import "./ShopSwitcherDetailModal.scss";
import { FormattedMessage } from "react-intl";
import IconCross from "app/pages/.shared/static/icons/IconCross";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";
import ShopSwitcherDetailContent from "app/pages/Home/ShopSwitcher/ShopSwitcherDetailContent";
import { Form, Formik } from "formik";
import { sendTagOnChangeLanguage } from "app/utils/analytics";
import AppGlobalsContext from "app/AppGlobalsContext";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import classNames from "classnames";
import { BRANDS } from "app/constants";
import RelativeLink from "app/pages/.shared/RelativeLink";
import LogoBrandContainer from "app/pages/.shared/LogoBrand/LogoBrandContainer";
import IconCircleClose from "app/pages/.shared/IconCircleClose";
import AsidePanel from "app/pages/.shared/AsidePanel/AsidePanel";
import { redirectToShopUrl } from "app/utils/utils";

const ShopSwitcherDetailModal = ({
	isOpened,
	close,
	shops = [],
	currentShop = "",
	currentCurrency = "",
	brand,
	updateCurrency = () => {},
	hideSwitcherModal = () => {},
}) => {
	const { resolution } = useContext(AppGlobalsContext);

	const isMobile = resolution === RESOLUTION.MEDIUM || resolution === RESOLUTION.SMALL;

	const handleSubmit = useCallback(
		(values, { setSubmitting }) => {
			const [currentLanguageCode, currentCountryCode] = currentShop.split("-");
			const selectedCurrency = values?.currency;
			const selectedCurrencyCode = selectedCurrency?.code;
			const selectedLanguageCode = values?.language?.code;
			const selectedCountryCode = values?.country?.code;

			setSubmitting(false);
			hideSwitcherModal();

			const languageChanged = selectedLanguageCode !== currentLanguageCode;
			const countryChanged = selectedCountryCode !== currentCountryCode;
			const currencyChanged = currentCurrency !== selectedCurrencyCode;

			if (currencyChanged) {
				updateCurrency(selectedCurrency?.code);
			}

			// Si le code du pays sélectionné diffère du code actuel, on envoie vers '/'
			// du nouveau pays sélectionné, qui par défaut renvoie vers fullbackUrl
			if (countryChanged) {
				redirectToShopUrl(selectedLanguageCode, selectedCountryCode);
			} else if (languageChanged) {
				sendTagOnChangeLanguage(selectedLanguageCode);

				const url = new URL(window.location.href);
				url.pathname = url.pathname.replace(
					`/${currentLanguageCode}`,
					`/${selectedLanguageCode}`
				);
				window.location.href = url.href;
			}
		},
		[currentShop, currentCurrency]
	);

	const modalContent = (
		<div className="shop-switcher-detail-modal__content">
			{!isMobile && (
				<div className="shop-switcher-detail-modal__close" onClick={close}>
					<IconCross />
				</div>
			)}
			<Typography
				className="shop-switcher-detail-modal__title"
				isBold
				variant={TYPOGRAPHY_VARIANTS.XL}
			>
				<FormattedMessage id="general.shop.switcher.detail.title" />
			</Typography>
			<Formik
				initialValues={{
					country: { code: "", label: "" },
					language: { code: "", label: "" },
					currency: { value: "", symbol: "" },
				}}
				validateOnChange={false}
				validateOnBlur={false}
				onSubmit={handleSubmit}
				initialTouched={{
					country: true,
					language: true,
					currency: true,
				}}
			>
				{() => (
					<Form>
						<ShopSwitcherDetailContent
							shops={shops}
							currentShop={currentShop}
							currentCurrency={currentCurrency}
						/>
					</Form>
				)}
			</Formik>
		</div>
	);

	return isMobile ? (
		<AsidePanel
			isOpen={isOpened}
			className={classNames("shop-switcher-detail-modal__side-panel ", {
				"shop-switcher-detail-modal--eh": brand === BRANDS.EK,
			})}
			data-testid="shop-switcher-detail-modal-side-panel"
		>
			<div className="shop-switcher-detail-modal__side-panel-header">
				<RelativeLink to={{ pathname: "/" }} onClick={close}>
					<LogoBrandContainer />
				</RelativeLink>
				<IconCircleClose width={25} height={25} onClick={close} />
			</div>
			{modalContent}
		</AsidePanel>
	) : (
		<Modal
			isOpen={isOpened}
			onRequestClose={close}
			className="shop-switcher-detail-modal"
			overlayClassName="shop-switcher-detail-modal__overlay"
		>
			{modalContent}
		</Modal>
	);
};

ShopSwitcherDetailModal.propTypes = {
	brand: PropTypes.string,
	isOpened: PropTypes.bool,
	close: PropTypes.func,
	shops: PropTypes.object,
	currentShop: PropTypes.string,
	updateCurrency: PropTypes.func,
	currentCurrency: PropTypes.string,
	hideSwitcherModal: PropTypes.func,
};

export default memo(ShopSwitcherDetailModal);
